import ApiHttp from "./Settings";
import ForumNewMessage from "../classes/ForumNewMessage";
import Shop from "../classes/PostClasses/Shop";
import moment from "moment/moment";

const querystring = require('querystring');

export default {
    getEnvironment: function () {
        let url: string = ApiHttp.defaults.baseURL ?? 'https://api.skgcollect.nl/v1';
        if (url.includes('.staging.')) {
            return 'staging';
        } else if (url.includes('.dev.')) {
            return 'dev';
        } else {
            return 'prod';
        }
    },
    shops: {
        post: function (requestData: Shop) {
            return ApiHttp.post('/shop', requestData)
        },
        get: function (refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            return ApiHttp.get('/shop', {cache: {ignoreCache: refreshing}})
        },
        getOne: function (shopId: number, refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            return ApiHttp.get('/shop/' + shopId, {cache: {ignoreCache: refreshing}});
        },
        getFavorites: function (ids: string) {
            return ApiHttp.get('/shop?ids=' + ids);
        },
        getOrderSettings: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/order/settings')
        },
        getPopularProducts: function (sessionToken: string | null, shopId: number, locationId: number | null) {
            let urlTemp = '/shop/' + shopId + '/product/getPopularProducts'
            if (locationId !== null) {
                urlTemp += `?location=${locationId}`
            }
            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {"X-Session-Token": sessionToken}
                };
            }
            return ApiHttp.get(urlTemp, config);
        },
        getContactList: function (sessionToken: string, shopId: number, locationId: number | null, refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            let config = {
                headers: {"X-Session-Token": sessionToken},
                cache: {ignoreCache: refreshing}
            };

            let urlTemp = '/shop/' + shopId + '/contactList';
            if (locationId !== null) {
                urlTemp += `?location=${locationId}`;
            }

            return ApiHttp.get(urlTemp, config);
        },
        getGroups: function (sessionToken: string, shopId: number) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };
            return ApiHttp.get('/shop/' + shopId + '/group', config);
        },
        getGenericDonation: function (shopId: number, donationId: number, securityCode: string) {
            return ApiHttp.get('/shop/' + shopId + '/donation/' + donationId + "?securityCode=" + securityCode);
        },
        getGenericDonationBundle: function (shopId: number, donationId: number, securityCode: string) {
            return ApiHttp.get('/shop/' + shopId + '/donationBundle/' + donationId + '?securityCode=' + securityCode);
        },
        getDonationToOtherShop: function (shopId: number, donationId: number, securityCode: string) {
            return ApiHttp.get('/shop/' + shopId + '/donation/' + donationId + "?securityCode=" + securityCode, {headers: {"X-Session-Token": ""}});
        },
        getHomeBlocks: function (sessionToken: string | null, shopId: number, locationId: number | null, refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            let config = {
                cache: {ignoreCache: refreshing},
                headers: {}
            };

            if (sessionToken !== null) {
                config.headers = {"X-Session-Token": sessionToken}
            }

            let urlTemp = '/shop/' + shopId + '/app/setting/home'
            if (locationId !== null) {
                urlTemp += `?location=${locationId}`;
            }
            return ApiHttp.get(urlTemp, config);
        },
        getLocations: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/location');
        },
        getDisclaimer: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/disclaimer/current')
        },
        getGeneralTermsAndConditions: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/generalTermsAndConditions/current')
        },
        getPrivacyStatement: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/privacyStatement/current')
        },
        product: {
            get: function (sessionToken: string | null, shopId: number, locationId: number | null, labelKey?: string, notLabelKey?: string) {
                let urlTemp = '/shop/' + shopId + '/product?';

                if (locationId !== null) {
                    urlTemp += `location=${locationId}&`
                }

                if (labelKey !== undefined) {
                    urlTemp += `labelKey=${labelKey}&`;
                }

                if (notLabelKey !== undefined) {
                    urlTemp += `notLabelKey=${notLabelKey}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getOne: function (sessionToken: string | null, shopId: number, productId: number) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get('/shop/' + shopId + '/product/' + productId, config);
            },
            getCount: function (sessionToken: string | null, shopId: number, locationId: number | null, labelKey?: string, notLabelKey?: string) {
                let urlTemp = '/shop/' + shopId + '/product?count';

                if (locationId !== null) {
                    urlTemp += '&location=' + locationId;
                }

                if (labelKey !== undefined) {
                    urlTemp += '&labelKey=' + labelKey;
                }
                if (notLabelKey !== undefined) {
                    urlTemp += '&notLabelKey=' + notLabelKey;
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }
                return ApiHttp.get(urlTemp, config);
            }
        },
        charity: {
            get: function (sessionToken: string | null, shopId: number, locationId: number | null, labelKey?: string, notLabelKey?: string) {
                let urlTemp = '/shop/' + shopId + '/charity?show=short&orderBy=order';

                if (locationId !== null) {
                    urlTemp += '&location=' + locationId;
                }

                if (labelKey !== undefined) {
                    urlTemp += '&labelKey=' + labelKey;
                }

                if (notLabelKey !== undefined) {
                    urlTemp += '&notLabelKey=' + notLabelKey;
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config)
            },
            getOne: function (sessionToken: string | null, shopId: number, charityId: number, action?: string) {
                let urlTemp = '/shop/' + shopId + '/charity/' + charityId;

                if (action !== undefined) {
                    urlTemp = urlTemp + '?action=' + action;
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            }
        },
        agenda: {
            getByYearAndMonth: function (sessionToken: string | null, shopId: number, year: number, month: number, locationId: number | null, labelIds: string | null) {
                let urlTemp = '/shop/' + shopId + '/agenda?show=short&year=' + year + '&month=' + month;
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getByDate: function (sessionToken: string | null, shopId: number, date: any, locationId: number | null, labelIds: string | null) {
                let urlTemp = '/shop/' + shopId + '/agenda?show=short&date=' + date;
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getOne: function (sessionToken: string | null, shopId: number, agendaId: number) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get('/shop/' + shopId + '/agenda/' + agendaId, config);
            },
            getAll: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
                let urlTemp = '/shop/' + shopId + '/agenda?show=short&startDateFrom=' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss").replace(' ', 'T')
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getAllCount: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
                let urlTemp = '/shop/' + shopId + '/agenda/count?show=short&startDateFrom=' + moment(new Date()).format("YYYY-MM-DD HH:mm:ss").replace(' ', 'T')
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getServiceRoster: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
                let currentDate = new Date();
                let urlTemp = '/shop/' + shopId + '/agenda?show=short&labelKey=kd&startDateFrom=' + currentDate.toISOString();
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            getServiceRosterCount: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
                let currentDate = new Date();
                let urlTemp = '/shop/' + shopId + '/agenda/count?show=short&labelKey=kd&startDateFrom=' + currentDate.toISOString();
                if (locationId !== null) {
                    urlTemp += `&location=${locationId}`;
                }
                if (labelIds !== null && labelIds !== "") {
                    urlTemp += `&label=${labelIds}`
                }

                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.get(urlTemp, config);
            },
            participant: {
                get: function (sessionToken: string | null, customerId: number, agendaId: number) {

                    let config = {};
                    if (sessionToken !== null) {
                        config = {
                            headers: {'X-Session-Token': sessionToken}
                        }
                    }

                    return ApiHttp.get('/customer/' + customerId + '/agenda/' + agendaId + '/participant', config);
                },
                addOrEdit: function (sessionToken: string, customerId: number, agendaId: number, count: number) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    let data = {
                        count: count
                    }

                    return ApiHttp.post('/customer/' + customerId + '/agenda/' + agendaId + '/participant', data, config);
                },
                delete: function (sessionToken: string, customerId: number, agendaId: number) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    return ApiHttp.delete('/customer/' + customerId + '/agenda/' + agendaId + '/participant', config);
                }
            }
        },
        order: {
            create: function (sessionToken: string | null, shopId: number, orderData: any, basketItems: any, kortingsCode: any) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                let data = {
                    orderData: orderData,
                    returnUrl: orderData.returnUrl,
                    bic: orderData.bic,
                    orders: basketItems,
                    kortingsCode: kortingsCode
                };
                return ApiHttp.post('/shop/' + shopId + '/order', data, config);
            }
        },
        faq: {
            getAll: function (shopId: number) {
                return ApiHttp.get('/shop/' + shopId + '/faq')
            },
            getOne: function (shopId: number, faqId: number) {
                return ApiHttp.get('/shop/' + shopId + '/faq/' + faqId);
            }
        },
        feedback: {
            send: function (shopId: number, data: any, sessionToken: string | null) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }
                return ApiHttp.post('/shop/' + shopId + '/contact', data, config);
            }
        }
    },
    customer: {
        add: function (data: any, shopId: number) {
            return ApiHttp.post('/shop/' + shopId + '/customer', data)
        },
        delete: function (sessionToken: string, customerId: number) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            return ApiHttp.delete('/customer/' + customerId, config)
        },
        login: function (data: any, shopId: number) {
            return ApiHttp.post('/shop/' + shopId + '/customer/login', data)
        },
        logout: function (sessionToken: string, customerId: number) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            return ApiHttp.delete('/customer/' + customerId + '/session', config);
        },
        getOne: function (sessionToken: string, customerId: number, refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            let config = {
                headers: {"X-Session-Token": sessionToken},
                cache: {ignoreCache: refreshing}
            };

            return ApiHttp.get('/customer/' + customerId, config);
        },
        usage: function (sessionToken: string, customerId: number, refreshing?: boolean) {
            if (refreshing === undefined) {
                refreshing = false;
            }

            let config = {
                headers: {"X-Session-Token": sessionToken},
                cache: {ignoreCache: refreshing}
            };

            return ApiHttp.get('/customer/' + customerId + '/usageStats', config);
        },
        edit: function (sessionToken: string, customerId: number, shopId: number, data: any) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId, data, config);
        },
        passReset: function (shopId: number, emailAddress: string, resetPassUrl: string) {
            let data = {
                email: emailAddress,
                resetPassUrl: resetPassUrl,
            };
            return ApiHttp.post('/shop/' + shopId + '/customer/sendPassResetLink', data);
        },
        setPassword: function (shopId: number, data: any) {
            return ApiHttp.post('/shop/' + shopId + '/customer/resetPassword', data);
        },
        addToGroup: function (sessionToken: string, customerId: number, groupId: number, data: any) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            return ApiHttp.post('/customer/' + customerId + '/group/' + groupId, data, config);
        },
        deleteFromGroup: function (sessionToken: string, customerId: number, groupId: number) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            return ApiHttp.delete('/customer/' + customerId + '/group/' + groupId, config);
        },
        sendEmailValidationLink: function (sessionToken: string, customerId: number, shopId: number, emailValidationUrl: string) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };
            let data = {
                emailValidationUrl: emailValidationUrl
            }
            return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/emailValidation/sendLink', data, config);
        },
        processValidationToken: function (sessionToken: string, customerId: number, shopId: number, validationToken: string) {
            let config = {
                headers: {"X-Session-Token": sessionToken},
            };
            let data = {
                token: validationToken
            }
            return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/emailValidation/processToken', data, config);
        },
        retry: {
            getDonation: function (sessionToken: string, customerId: number, shopId: number, donationId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/donation/' + donationId + '/retryPayment', config);
            },
            postDonation: function (sessionToken: string, customerId: number, shopId: number, donationId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/donation/' + donationId + '/retryPayment', data, config)
            },
            getContribution: function (sessionToken: string, customerId: number, shopId: number, contributionId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/contribution/' + contributionId + '/retryPayment', config);
            },
            postContribution: function (sessionToken: string, customerId: number, shopId: number, contributionId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/contribution/' + contributionId + '/retryPayment', data, config)
            },
            getOrder: function (sessionToken: string, customerId: number, shopId: number, orderId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/order/' + orderId + '/retryPayment', config);
            },
            postOrder: function (sessionToken: string, customerId: number, shopId: number, orderId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                }

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/order/' + orderId + '/retryPayment', data, config)
            },
        },
        bankaccount: {
            get: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/bankAccount', config);
            },
            getAll: function (sessionToken: string, customerId: number, shopId: number, refreshing?: boolean) {
                if (refreshing === undefined) {
                    refreshing = false;
                }

                let config = {
                    headers: {"X-Session-Token": sessionToken},
                    cache: {ignoreCache: refreshing}
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/bankAccount?active=false', config);
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, bankId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/bankAccount/' + bankId + '?active=false', config);
            },
            add: function (sessionToken: string, customerId: number, shopId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/bankAccount', data, config);
            },
            edit: function (sessionToken: string, bankAccountId: number, customerId: number, shopId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/bankAccount/' + bankAccountId, data, config);
            }
        },
        order: {
            get: function (sessionToken: string, customerId: number, shopId: number, paid?: any, createdAtFrom?: string, createdAtUntil?: string, page: number = 1) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let params = {
                    paid: paid,
                    createdAtFrom: createdAtFrom,
                    createdAtUntil: createdAtUntil,
                    page: page,
                    limit: 25
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/order?' + querystring.stringify(params), config);
            },
            count: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/order/count', config);
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, orderId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/order/' + orderId, config);
            }
        },
        credit: {
            get: function (sessionToken: string, customerId: number, shopId: number, paid?: any, createdAtFrom?: string, createdAtUntil?: string, page: number = 1) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let params = {
                    paid: paid,
                    createdAtFrom: createdAtFrom,
                    createdAtUntil: createdAtUntil,
                    page: page,
                    limit: 25
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/credit?' + querystring.stringify(params), config);
            },
            count: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/credit/count', config);
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, creditId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/credit/' + creditId, config);
            },
            add: function (sessionToken: string, customerId: number, shopId: number, data: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/credit', data, config);
            },
            getAmount: function (sessionToken: string, customerId: number, shopId: number, refreshing: boolean = false) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                    cache: {ignoreCache: refreshing}
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/credit/amount', config);
            }
        },
        commitment: {
            get: function (sessionToken: string, customerId: number, shopId: number, createdAtFrom?: string, createdAtUntil?: string, page: number = 1) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let params = {
                    createdAtFrom: createdAtFrom,
                    createdAtUntil: createdAtUntil,
                    page: page,
                    limit: 25
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/commitment?' + querystring.stringify(params), config)
            },
            count: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/commitment/count', config)
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, commitmentId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/commitment/' + commitmentId, config);
            },
            add: function (sessionToken: string, customerId: number, shopId: number, data: any, directIdeal?: boolean) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let urlTemp = '/shop/' + shopId + '/customer/' + customerId + '/commitment';

                if (directIdeal !== undefined) {
                    urlTemp = urlTemp + '?directIdeal=' + directIdeal;
                }

                return ApiHttp.post(urlTemp, data, config);
            },
            addUnauthenticated: function (shopId: number, data: any, directIdeal?: boolean) {
                let urlTemp = '/shop/' + shopId + '/commitment';

                if (directIdeal !== undefined) {
                    urlTemp = urlTemp + '?directIdeal=' + directIdeal;
                }

                return ApiHttp.post(urlTemp, data);
            },
            addYears: function (sessionToken: string, customerId: number, shopId: number, data: any, directIdeal?: boolean) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let urlTemp = '/shop/' + shopId + '/customer/' + customerId + '/commitment/years';

                if (directIdeal !== undefined) {
                    urlTemp = urlTemp + '?directIdeal=' + directIdeal;
                }

                return ApiHttp.post(urlTemp, data, config);
            },
            addYearsUnauthenticated: function (shopId: number, data: any, directIdeal?: boolean) {
                let urlTemp = '/shop/' + shopId + '/commitment/years';

                if (directIdeal !== undefined) {
                    urlTemp = urlTemp + '?directIdeal=' + directIdeal;
                }

                return ApiHttp.post(urlTemp, data);
            }
        },
        contribution: {
            get: function (sessionToken: string, customerId: number, shopId: number, paid?: any, createdAtFrom?: string, createdAtUntil?: string, page: number = 1) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let params = {
                    paid: paid,
                    createdAtFrom: createdAtFrom,
                    createdAtUntil: createdAtUntil,
                    page: page,
                    limit: 25
                }

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/contribution?' + querystring.stringify(params), config);
            },
            count: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/contribution/count', config);
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, contributionId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/contribution/' + contributionId, config);
            },
            add: function (sessionToken: string | null, customerId: number, shopId: number, data: any) {
                if (sessionToken ===  null) {
                    return this.addUnauthenticated(shopId, data);
                }
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/contribution', data, config);
            },
            addUnauthenticated: function (shopId: number, data: any) {
                return ApiHttp.post('/shop/' + shopId + '/contribution', data);
            },
            addBundle: function (sessionToken: string | null, customerId: number, shopId: number, data: any) {
                if (sessionToken === null) {
                    return this.addBundleUnauthenticated(shopId, data);
                }
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/contributionBundle', data, config);
            },
            addBundleUnauthenticated: function (shopId: number, data: any) {
                return ApiHttp.post('/shop/' + shopId + '/contributionBundle', data);
            },
            getBundle: function (sessionToken: string, customerId: number, shopId: number, bundleId: any) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/contributionBundle/' + bundleId, config);
            },
        },
        donation: {
            get: function (sessionToken: string | null, customerId: number, shopId: number, paid?: any, createdAtFrom?: string, createdAtUntil?: string, page: number = 1) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                let params = {
                    paid: paid,
                    createdAtFrom: createdAtFrom,
                    createdAtUntil: createdAtUntil,
                    page: page,
                    limit: 25
                }
                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/donation?' + querystring.stringify(params), config);
            },
            count: function (sessionToken: string, customerId: number, shopId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/donation/count', config);
            },
            getOne: function (sessionToken: string, customerId: number, shopId: number, donationId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/donation/' + donationId, config);
            },
            getBundle: function (sessionToken: string, customerId: number, shopId: number, bundleId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/customer/' + customerId + '/donationBundle/' + bundleId, config);
            },
            add: function (sessionToken: string | null, customerId: number, shopId: number, data: any) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.post('/shop/' + shopId + '/customer/' + customerId + '/donation', data, config);
            },
            addGeneric: function (shopId: number, data: any, sessionToken: string | null = null) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {"X-Session-Token": sessionToken},
                    };
                }

                return ApiHttp.post('/shop/' + shopId + '/donation', data, config)
            },
            addBundle: function (sessionToken: string | null, shopId: number, data: any) {
                let config = {};
                if (sessionToken !== null) {
                    config = {
                        headers: {'X-Session-Token': sessionToken}
                    }
                }

                return ApiHttp.post('/shop/' + shopId + '/donationBundle', data, config);
            }
        },
        pushNotification: {
            set: function (sessionToken: string, customerId: number, active: boolean) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.post('/customer/' + customerId + '/pushNotification', {active: active}, config);
            },
            get: function (sessionToken: string, customerId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/customer/' + customerId + '/pushNotification', config);
            }
        },
        pknLrp: {
            requestMemberId: {
                sendToken: function (sessionToken: string, customerId: number, email: string, dateOfBirth: string) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    let data = {
                        email: email,
                        dateOfBirth: dateOfBirth,
                    }
                    return ApiHttp.post('/customer/' + customerId + '/pkn-lrp/request-member-id/send-token', data, config);
                },
                processToken: function (sessionToken: string, customerId: number, email: string, dateOfBirth: string, token: string) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    let data = {
                        email: email,
                        dateOfBirth: dateOfBirth,
                        token: token,
                    }
                    return ApiHttp.post('/customer/' + customerId + '/pkn-lrp/request-member-id/process-token', data, config);
                }
            }
        }
    },
    news: {
        get: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
            let urlTemp = '/shop/' + shopId + '/news?';
            if (locationId !== null) {
                urlTemp += `location=${locationId}&`
            }
            if (labelIds !== null && labelIds !== "") {
                urlTemp += `label=${labelIds}`
            }

            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {'X-Session-Token': sessionToken}
                }
            }

            return ApiHttp.get(urlTemp, config)
        },
        getCount: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
            let urlTemp = '/shop/' + shopId + '/news/count?';
            if (locationId !== null) {
                urlTemp += `location=${locationId}&`
            }
            if (labelIds !== null && labelIds !== "") {
                urlTemp += `label=${labelIds}`
            }

            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {'X-Session-Token': sessionToken}
                }
            }

            return ApiHttp.get(urlTemp, config)
        },
        getCommunity: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
            let urlTemp = '/shop/' + shopId + '/news?labelKey=kb'
            if (locationId !== null) {
                urlTemp += `&location=${locationId}`
            }
            if (labelIds !== null && labelIds !== "") {
                urlTemp += `&label=${labelIds}`
            }

            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {'X-Session-Token': sessionToken}
                }
            }

            return ApiHttp.get(urlTemp, config)
        },
        getCommunityCount: function (sessionToken: string | null, shopId: number, locationId: number | null, labelIds: string | null) {
            let urlTemp = '/shop/' + shopId + '/news/count?labelKey=kb'
            if (locationId !== null) {
                urlTemp += `&location=${locationId}`
            }
            if (labelIds !== null && labelIds !== "") {
                urlTemp += `&label=${labelIds}`
            }

            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {'X-Session-Token': sessionToken}
                }
            }

            return ApiHttp.get(urlTemp, config)
        },
        getOne: function (sessionToken: string | null, shopId: number, newsId: number) {
            let config = {};
            if (sessionToken !== null) {
                config = {
                    headers: {'X-Session-Token': sessionToken}
                }
            }

            return ApiHttp.get('/shop/' + shopId + '/news/' + newsId, config)
        }
    },
    order: {
        create: function (shopId: number, data: any) {
            return ApiHttp.post('/shop/' + shopId + '/order', data)
        }
    },
    ideal: {
        issuerList: function () {
            return ApiHttp.get('/ideal/issuerList');
        },
        create: function (data: any) {
            return ApiHttp.post('/ideal', data);
        }
    },
    paypal: {
        create: function (data: any) {
            return ApiHttp.post('/paypal', data);
        }
    },
    pushNotification: {
        received: function (id: number) {
            let data = {
                id: id
            };
            return ApiHttp.post('/pushNotification/received', data);
        }
    }
    ,
    appClient: {
        changeDeviceTokenAndUpdate: function (oldDeviceToken: string, deviceToken: string, platform?: string) {
            let data = {
                oldDeviceToken: oldDeviceToken,
                deviceToken: deviceToken,
                platform: platform
            };

            return ApiHttp.post('/app/client', data);
        },
        createOrUpdate: function (deviceToken: string, platform?: string) {
            let data = {
                deviceToken: deviceToken,
                platform: platform
            };

            return ApiHttp.post('/app/client', data);
        }
    },
    shoppingBasket: {
        add: function (shopId: number, artikelId: number, aantal: number, kenmerken: string, current: any) {
            let data = {
                artikelId: artikelId,
                aantal: aantal,
                kenmerken: kenmerken,
                current: current
            };

            return ApiHttp.post('/shop/' + shopId + '/basket', data);
        },
        edit: function (shopId: number, basketItemId: number, aantal: number, current: any) {
            let data = {
                aantal: aantal,
                current: current
            };

            return ApiHttp.post('/shop/' + shopId + '/basket/' + basketItemId, data);
        },
        current: function (shopId: number, current: any) {
            let data = {
                current: current
            };

            return ApiHttp.post('/shop/' + shopId + '/basket/current', data);
        },
    },
    QR: {
        push: function (data: any) {
            return ApiHttp.post('/qr', data);
        },
        getIdealQR: function (code: string) {
            return ApiHttp.get('/idealQr/' + code)
        }
    },
    LRP: {
        get: function (type: string, uuid: string) {
            return ApiHttp.get(`/pkn-lrp/${type}/${uuid}`)
        }
    },
    lang: {
        get: function (shopId: number) {
            return ApiHttp.get('/shop/' + shopId + '/lang');
        }
    },
    appVersion: {
        getAndroid: function () {
            return ApiHttp.get('/version/android');
        },
        getIos: function () {
            return ApiHttp.get('/version/ios')
        }
    },
    feedback: {
        send: function (data: any) {
            return ApiHttp.post('/contact', data)
        }
    },
    forum: {
        getTotalNewMessageCount: function (sessionToken: string, shopId: number, locationId: number | null) {

            let config = {
                headers: {"X-Session-Token": sessionToken},
            };

            let urlTemp = '/shop/' + shopId + '/forum/topic/message/new';
            if (locationId !== null) {
                urlTemp += `?location=${locationId}`;
            }
            return ApiHttp.get(urlTemp, config);
        },
        topic: {
            getAll: function (sessionToken: string, shopId: number, locationId: number | null) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                let urlTemp = '/shop/' + shopId + '/forum/topic'
                if (locationId !== null) {
                    urlTemp += `?location=${locationId}`
                }
                return ApiHttp.get(urlTemp, config)
            },
            getOne: function (sessionToken: string, shopId: number, topicId: number) {
                let config = {
                    headers: {"X-Session-Token": sessionToken},
                };

                return ApiHttp.get('/shop/' + shopId + '/forum/topic/' + topicId, config)
            },
            message: {
                getOne: function (sessionToken: string, shopId: number, topicId: number, messageId: number) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    return ApiHttp.get('/shop/' + shopId + '/forum/topic/' + topicId + '/message/' + messageId, config)
                },
                getAll: function (sessionToken: string, shopId: number, topicId: number, createdAtFrom: string | undefined, createdAtUntil: string | undefined) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    let urlTemp = '/shop/' + shopId + '/forum/topic/' + topicId + '/message?orderBy=createdAt&orderDirection=desc';
                    if (createdAtFrom !== undefined) {
                        let date = new Date(createdAtFrom);

                        urlTemp += "&createdAtFrom=" + date.toISOString();
                    }
                    if (createdAtUntil !== undefined) {
                        let date = new Date(createdAtUntil);

                        urlTemp += "&createdAtUntil=" + date.toISOString();
                    }
                    return ApiHttp.get(urlTemp, config)
                },
                getMembers: function (sessionToken: string, shopId: number, topicId: number) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    return ApiHttp.get('/shop/' + shopId + '/forum/topic/' + topicId + '/member', config)
                },
                getNextPage: function (sessionToken: string, shopId: number, topicId: number, page: number, createdAtFrom: string | undefined, createdAtUntil: string | undefined) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    let urlTemp = '/shop/' + shopId + '/forum/topic/' + topicId + '/message?orderBy=createdAt&orderDirection=desc&page=' + page;
                    if (createdAtFrom !== undefined) {
                        let date = new Date(createdAtFrom);

                        urlTemp += "&createdAtFrom=" + date.toISOString();
                    }
                    if (createdAtUntil !== undefined) {
                        let date = new Date(createdAtUntil);

                        urlTemp += "&createdAtUntil=" + date.toISOString();
                    }
                    return ApiHttp.get(urlTemp, config);
                },
                post: function (sessionToken: string, shopId: number, topicId: number, data: ForumNewMessage) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    return ApiHttp.post('/shop/' + shopId + '/forum/topic/' + topicId + '/message', data, config)
                },
                delete: function (sessionToken: string, shopId: number, topicId: number, messageId: number) {
                    let config = {
                        headers: {"X-Session-Token": sessionToken},
                    };

                    return ApiHttp.delete('/shop/' + shopId + '/forum/topic/' + topicId + '/message/' + messageId, config);
                }
            }
        }
    }
}