import axios from 'axios';
import {version} from '../../package.json';
import {setupCache} from "axios-cache-adapter";
import {useGlobalState} from "../App";

let lodash = require('lodash');
let ApiUrl = "https://api.skgcollect.nl/v1";
let AppToken = "c4MTYWiT1hxn4q20lzVnBR8TYqUbjV";
let AppKey = "appostel";
let AppVersion = version;
let maxCacheAge = 5 * 60 * 1000;

// console.log("running in staging");
// ApiUrl = "https://api.staging.skgcollect.nl/v1";
// AppVersion = 'latest';
// maxCacheAge = 1;

// console.log("running in development");
// ApiUrl = "https://api.dev.skgcollect.nl/v1";
// AppVersion = 'latest';
// maxCacheAge = 1;

const ApiHttp = axios.create({
    baseURL: ApiUrl,
    headers: {
        "X-App-Token": AppToken,
        "X-App-Version": AppVersion,
        "X-App-Key": AppKey,
    }
});

const cache = setupCache({
    maxAge: maxCacheAge,
    ignoreCache: true
});

ApiHttp.defaults.adapter = cache.adapter;

ApiHttp.interceptors.response.use(function (response): any {
    response.data = lodash.cloneDeep(response.data);
    return response;
}, function (error) {
    if (error.response !== undefined) {
        if ((error.response.data === undefined || error.response.data.type === undefined || error.response.data.type !== 'userFriendly') && (process.env.NODE_ENV !== 'development' || window.confirm('redirect?') === true)) {
            if (error.response.data !== null && (error.response.data.code === 1420 || error.response.data.code === 1421 || error.response.data.code === 1422 || error.response.data.code === 1423 || error.response.data.code === 1424)) {
                window.location.href = '/error/142';
            } else {
                window.location.href = '/error/' + error.response.status;
            }
        }
    } else {
        console.log(error.config.url)
        // if(!(error.config.url.indexOf('/commitment') && error.config.method === "post")) {
        if (process.env.NODE_ENV !== 'development' || window.confirm('redirect?') === true) {
            window.location.href = '/error/network';
        }
        // }
    }

    return Promise.reject(error);
});

export default ApiHttp;